export const ALCHEMY_ID = '86wTK178jC4XNO9sh-iVI7P5fV1tg_Dx';

export const AirDropperAddress = {
  1: '0x69BE2c21Ea48D1a9C18c71D3E606c60152Ed58e3',
  5: '0x17e99256d1fBAd63ba180f7a625fB18121e77057',
};
export const TokenAddress = {
  1: '0xc64FCE609cbC4e857cebdfFdE59d74f70D72B148',
  5: '0xAc998f3417758447da8Bea801098BD18098a48cA',
};
export const CHAIN_ID = 5;
export const BaseFee = 0.0002;
